import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import GetInTouchButton from '@app/components/Forms/GetInTouch/GetInTouchButton';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';
import CustomMenu from '@app/components/UI/Header/CustomMenu';
import { menu } from '@app/components/UI/Header/Menu';
import MobileMenu from '@app/components/UI/Header/MobileMenu';

type HeaderProps = RouteComponentProps<{}>;

const logoSvg = require('@app/images/extended-logo.svg');

const Header: React.FC<HeaderProps> = (props) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		setIsMenuOpen(false);
	}, [props.location]);

	const toggleMenu  = (event: React.MouseEvent<HTMLElement>): void =>  {
		setIsMenuOpen(prevState => !prevState);
	};

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	return (
		<header className="site-header">
			<nav className="navbar navbar-fixed-top">
				<div className="navbar-wrapper">
					<NavLink to="/">
						<img className="site-header-logo" src={logoSvg} alt="Logo" />
					</NavLink>
					<CustomMenu items={menu} />
					<div className="site-header-buttons">
						<GetInTouchButton btnText="Schedule" btnClassName="capture-bold btn btn-sm btn-secondary find-a-vet" />
						{!isMobile &&
							<a
								href="https://lapoflovejobs.workable.com/"
								target="_blank"
								className="btn btn-sm btn-primary"
							>
								Join Our Team
							</a>
						}
						<NavLink to="/find-a-vet" className="btn btn-sm btn-primary">
							Find a Vet
						</NavLink>
						<MobileMenu />
					</div>
				</div>
			</nav>
		</header>
	);
};

export default withRouter((Header));