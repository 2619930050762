import * as React from 'react';
import {  RouteComponentProps, withRouter } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';
import '@app/scss/style.scss';
import ScrollToTop from '@app/components/LocalCommon/ScrollToTop/ScrollToTop';

type PageProps = RouteComponentProps & {withScrollTop: boolean};

const LayoutCut: React.FC<PageProps> = ({children, withScrollTop= true, ...rest}) => {
	return (
		<div className="app-container">
			<Header/>
			<div className="app-container-content">
				{children}
			</div>
			<Footer/>
			{withScrollTop && <ScrollToTop/>}
		</div>
	);
};

export default withRouter(LayoutCut) as any;