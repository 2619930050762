import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import CustomMenu from '@app/components/UI/Header/CustomMenu';
import { mobileMenu } from '@app/components/UI/Header/Menu';

const logoSvg = require('@app/images/extended-logo.svg');

const MobileMenu: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => {
		return history.listen(() => {
			setIsOpen(false);
		});
	}, []);

	return (
		<>
			<button type="button" className="btn btn-icon mobile-menu-button" onClick={() => setIsOpen(true)}>
				<img className="btn-icon-image" src={require('@app/images/menu.svg')} alt="search" width="16" />
				<img className="btn-icon-image_hover" src={require('@app/images/menu_white.svg')} alt="search" width="16" />
			</button>
			{isOpen && (
				<div className="mobile-menu">
					<header className="site-header">
						<nav className="navbar navbar-fixed-top">
							<div className="navbar-wrapper">
								<img className="site-header-logo" src={logoSvg} alt="Logo" />
								<div className="site-header-buttons">
									<button type="button" className="btn btn-icon mobile-menu-button" onClick={() => setIsOpen(false)}>
										<img className="btn-icon-image" src={require('@app/images/close.svg')} alt="close" />
										<img className="btn-icon-image_hover" src={require('@app/images/close_white.svg')} alt="close" />
									</button>
								</div>
							</div>
						</nav>
					</header>
					<div className="mobile-menu-body">
						<div className="w100">
							<CustomMenu items={mobileMenu} />
							<a
								href="https://lapoflovejobs.workable.com/"
								target="_blank"
								className="btn btn-sm btn-primary"
							>
								Join Our Team
							</a>
							<NavLink to="/find-a-vet" className="btn btn-sm btn-primary">
								Find a Vet
							</NavLink>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(MobileMenu);