import * as React from 'react';
import {Link} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import '@app/scss/components/bannerGlobal.scss';

const CookieBanner = () => {
	return (
		<CookieConsent
			overlay
			overlayClasses="overlayclass"
			acceptOnOverlayClick={true}
			disableStyles
			buttonText="Accept"
			buttonClasses="btn btn-sm btn-primary btn-opt"
			containerClasses="banner-global"
			contentClasses="banner-global__content"
		>
			We use cookies to provide and improve our services.
			By selecting Accept or continuing to use our site, you consent to their use.
			<br/>To learn more, read our <Link to="/policies/privacy-policy" className="inline-link">Privacy Policy</Link>.&nbsp;
		</CookieConsent>
	);
};

export default CookieBanner;