import {Action, Reducer} from 'redux';

import {addTask} from 'domain-task';

import {AppThunkAction} from '@app/store/index';
import {request} from '@app/components/Api';

export interface PageItemState<P> {
	page: P | null;
	path: string | null;
	isLoading: boolean;
}

export enum TypeKeys {
	REQUESTPAGE = 'REQUESTPAGE',
	RECIEVEPAGE = 'RECIEVEPAGE'
}

export interface RequestPageAction {
	type: TypeKeys.REQUESTPAGE;
	storageName: string | null;
	path: string;
}

export interface ReceivePageAction {
	type: TypeKeys.RECIEVEPAGE;
	storageName: string | null;
	page: any;
}

type KnownPageAction = RequestPageAction | ReceivePageAction;

export const actionCreators = ({
	loadPage: (storageName: string, path: string): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		const storeState = (getState() as any)[storageName];

		if (storeState.path !== path) {
			const fetchTask = request(
				'pageLoader',
				{path: path},
				getState()
			).then(data => dispatch({type: TypeKeys.RECIEVEPAGE, storageName: storageName, page: data}));

			addTask(fetchTask);
			dispatch({type: TypeKeys.REQUESTPAGE, storageName: storageName, path: path});
			
			return fetchTask;
		}
	}
});

export const reducer = <P>(storageName: string):Reducer<PageItemState<P>> => {
	return (state: PageItemState<P> = {isLoading: false, page: null, path: ''}, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.REQUESTPAGE:
					
					return {
						isLoading: true,
						page: state.page,
						path: action.path
					};
				case TypeKeys.RECIEVEPAGE:
					return {isLoading: false, page: action.page, path: action.page.path};
				default:
					const exhaustiveCheck: never = action;
			}
		}

		return state;
	};
};
