import { addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';

import { request } from '@common/react/components/Api';
import {BaseUser} from '@common/react/objects/BaseUser';
import {BaseParams} from '@common/react/objects/BaseParams';

import {BaseApplicationState, BaseAppThunkAction} from './';

export interface ItemState<T> {
	isLoading: boolean;
	id: number | null;
	itemPathOrId: string | number | null;
	item: T;
}

export enum TypeKeys {
	REQUESTITEM = 'REQUESTITEM',
	RECEIVEITEM = 'RECEIVEITEM',
	REMOVEITEM = 'REMOVEITEM',
}

interface RequestItemAction {
	type: TypeKeys.REQUESTITEM;
	storageName: string | null;
	itemPathOrId: string | number;
}

interface ReceiveItemAction {
	type: TypeKeys.RECEIVEITEM;
	storageName: string | null;
	item: any;
}

interface RemoveItemAction {
	type: TypeKeys.REMOVEITEM;
	storageName: string | null;
}

type KnownPageAction = RequestItemAction | ReceiveItemAction | RemoveItemAction;

export interface IActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	loadItem: (
		type: string, 
		path: string, 
		itemPathOrId: string | number, 
		defaultItem: any, 
		additionaParams?: BaseParams,
		customCheck?: (storeState: ItemState<any>) => boolean
	) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	updateItem: (type: string, data: any, checkProp?: string) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	removeItem: (type: string) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
}

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>() {
	return {
		loadItem: (
			type: string, 
			path: string, 
			itemPathOrId: string | number, 
			defaultItem: any, 
			additionaParams: BaseParams = {},
			customCheck?: (storeState: ItemState<any>) => boolean
		): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = getState()[type];
			const isNumber = typeof itemPathOrId === 'number' && Number.isFinite(itemPathOrId) && !(itemPathOrId % 1);

			const conditional = customCheck 
				? customCheck(storeState)
				: (isNumber && storeState.id !== +itemPathOrId) || 
					(!isNumber && storeState.itemPathOrId !== itemPathOrId) || 
					(storeState.item && storeState.item._type && storeState.item._type.toLowerCase() !== type.toLowerCase());
			
			if (conditional) {
				if (+itemPathOrId > 0 || (!isNumber && itemPathOrId !== '')) {
					const params = isNumber
						? {id: +itemPathOrId, ...additionaParams}
						: {path: itemPathOrId, ...additionaParams};

					const fetchTask = request(
						path,
						params,
						getState()
					).then(data => dispatch({type: TypeKeys.RECEIVEITEM, storageName: type, item: data}));

					addTask(fetchTask);
					dispatch({type: TypeKeys.REQUESTITEM, storageName: type, itemPathOrId: itemPathOrId});
					return fetchTask;
				} 
				
				dispatch({type: TypeKeys.RECEIVEITEM, storageName: type, item: defaultItem || {}});
				return Promise.resolve(defaultItem);
			}
			
			return Promise.resolve(storeState.item);
		},
		updateItem: (
			type: string, 
			data: any, 
			checkProp?: string
		): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[type],
				item = storeState.item;

			if (!checkProp || (checkProp && item && data[checkProp] === item[checkProp])) {
				dispatch({type: TypeKeys.RECEIVEITEM, storageName: type, item: {...item, ...data}});
			}
		},
		removeItem: (type: string): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({type: TypeKeys.REMOVEITEM, storageName: type});
		},
	};
}

export function getReducer<T>(storageName: string):Reducer<ItemState<T>> {
	return (state: ItemState<T>, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.REQUESTITEM:
					return {
						isLoading: true,
						item: state.item,
						id: Number(action.itemPathOrId),
						itemPathOrId: action.itemPathOrId
					};
				case TypeKeys.RECEIVEITEM:
					return {
						isLoading: false, 
						item: action.item, 
						id: typeof  action.item.id !== 'undefined' ? action.item.id : state.id, 
						itemPathOrId: null
					};
				case TypeKeys.REMOVEITEM:
					return {isLoading: false, item: null, id: null, itemPathOrId: null};
				default:
					const exhaustiveCheck: never = action;
			}
		} 
		
		return state || {isLoading: false, item: null, id: null, itemPathOrId: null};
	};
}