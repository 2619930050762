import * as React from 'react';
import {  RouteComponentProps, withRouter } from 'react-router-dom';

import { SentryErrorBoundary } from '@app/components/UI/ErrorBoundary/SentryErrorBoundary';
import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';
import FindVetBlock from '@app/components/UI/FindVetBlock/FindVetBlock';
import '@app/scss/style.scss';
import '@app/scss/components/chat.scss';
import ScrollToTop from '@app/components/LocalCommon/ScrollToTop/ScrollToTop';

type PageProps = RouteComponentProps & {withScrollTop: boolean};

const Layout: React.FC<PageProps> = ({children, withScrollTop  = true, ...rest}) => {
	return (
		<div className="app-container">
			<SentryErrorBoundary>
				<Header/>
				<div className="app-container-content">
					{children}
				</div>
				{
					<FindVetBlock/>
				}
				<Footer/>
				{withScrollTop && <ScrollToTop/>}
			</SentryErrorBoundary>
		</div>
	);
};

export default withRouter(Layout) as any;
