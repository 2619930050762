import * as React from 'react';

import {Redirect, useHistory} from 'react-router-dom';

import Search, { SearchProps } from 'antd/lib/input/Search';
import {Input} from 'antd';

import { Area } from '@app/objects/Area';

interface ComponentProps extends SearchProps {
	icon?: string;
	iconLocation?: boolean;
	widthBorder?: string;
	setSearchActive?: any;
	setSearch?: (value: string) => void;
	searchBody?: string;
	setValue?: (string) => void;
	tab?: string;
	autoFocus?: boolean;
}

const searchIcon = require('@app/images/search_white.svg');
const locationIcon = require('@app/images/pin.svg');

const SearchField = React.forwardRef<typeof Search, ComponentProps>((props, ref) => {
	const [areas, setArea] = React.useState<Area[]>([]);
	const [redirectUrl, setRedirect] = React.useState('');
	const [checkValidInput, setCheckValidInput] = React.useState<boolean>(true);
	const widthBorder = props.widthBorder || '1';

	const inputRef = React.useRef() as React.RefObject<Input>;

	const history = useHistory();

	const enterButton = (
		<button type="submit" className="search-field-button">
			<img src={props.icon || searchIcon} alt="Search Icon" />
		</button>
	);

	const handleOnSearch = (value) => {
		props.setSearch && props.setSearch(value.trim());
		history.push(`${props.searchBody ? props.searchBody : ''}?query=${value.trim()}`);
	};

	React.useEffect(() => {
		props.autoFocus 
			? inputRef.current && inputRef.current.focus()
			: null;
	}, [props.tab]);

	return (
		<div className="search-field-wrap">
			{redirectUrl &&
				<Redirect to={redirectUrl}/>
			}

			<Search
				ref={inputRef}
				placeholder={props.placeholder || 'Search'}
				className={`search-field search-field--border-${widthBorder} ${checkValidInput ? '' : 'search-field--border-error'}`}
				onSearch={(value) => {
					if (!isNaN(+value) && value.length === 5) {
						handleOnSearch(value);
					}
					// @ts-ignore
					props.setSearchActive ? props.setSearchActive(false) : null;
				}}
				style={{ width: 200 }}
				onChange={(e) => {
					if (!isNaN(+e.currentTarget.value) && e.currentTarget.value.length === 5) {
						props.setValue && props.setValue(e.currentTarget.value);
						setCheckValidInput(true);
					} else {
						setCheckValidInput(false);
					}
				}}
				enterButton={enterButton}
				value={props.value}
				prefix={props.iconLocation ? <img src={props.iconLocation && locationIcon} alt="pin"/> : ''}
			/>
		</div>
	);
});

export default SearchField;