import { 
	createStore, 
	applyMiddleware, 
	compose, 
	combineReducers, 
	GenericStoreEnhancer, 
	Store, 
	StoreEnhancerStoreCreator, 
	ReducersMapObject 
} from 'redux';
import thunk from 'redux-thunk';
import { History } from 'history';

import {BaseUser} from '@common/react/objects/BaseUser';

import {TypeKeys as LoginKeys} from './store/Login';

import {BaseApplicationState} from './store';

let store: any = null;

export default function configureStore<
	TUser extends BaseUser, 
	TApplicationState extends BaseApplicationState<TUser>
>(history: History, reducers: ReducersMapObject, initialState?: TApplicationState) {
	// Build middleware. These are functions that can process the actions before they reach the store.
	const windowIfDefined = typeof window === 'undefined' ? null : window as any;
	// If devTools is installed, connect to it
	const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => GenericStoreEnhancer;
	const createStoreWithMiddleware = compose(
		applyMiddleware(thunk),
		devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
	)(createStore);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = buildRootReducer(reducers);

	if (typeof createStoreWithMiddleware === 'function') {
		store = createStoreWithMiddleware(allReducers, initialState) as Store<TApplicationState>;
	}

	return store;
}

export function updateReducers(reducers) {
	if (store === null) {
		throw 'configureStore must be called first!';
	}

	store.replaceReducer(buildRootReducer(reducers));
}

function buildRootReducer<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(allReducers: ReducersMapObject) {
	return ( state, action ) => {
		if ( action.type === LoginKeys.CLEARSTATE ) {
			// tslint:disable-next-line:no-parameter-reassignment
			state = {};
		}

		return combineReducers<TApplicationState>(allReducers)(state, action);
	};
}