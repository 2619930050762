import * as React from 'react';

export {default as loadable} from '@loadable/component';

import {Loading} from '@common/react/components/UI/Loading/Loading';

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const loadableDelay = (promise) => {
	if (typeof window === 'undefined') return promise;

	let promiseErr;

	// tslint:disable-next-line:no-parameter-reassignment
	promise = promise.catch(err => promiseErr = err);

	return Promise.all([promise, delay(200)]).then(val => promiseErr ? Promise.reject(promiseErr) : val[0]);
};

export const params = {
	fallback: <Loading/>
};