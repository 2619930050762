export const dateFormat = function (
	date: number | null | undefined,
	params: Intl.DateTimeFormatOptions = { year: 'numeric', month:'2-digit', day: '2-digit' },
	locale: string = 'en-US'
): string {
	if (date === null || date === undefined) {
		return '';
	}

	const d: Date = new Date(date);

	return d.toLocaleDateString(locale, params);
};

export const dateTimeFormat = function (
	date: number | null | undefined,
	params: Intl.DateTimeFormatOptions = { hour:'2-digit', minute: '2-digit' },
	locale: string = 'en-US'
): string {
	if (date === null || date === undefined) {
		return '';
	}

	const d: Date = new Date(date);

	return d.toLocaleTimeString(locale, params);
};

export const getBackgroundUrl = function (image: string) {
	return `url("${image?.replace('\\', '/')}")`;
};

function transliterate(str: string): string {
	// tslint:disable-next-line:max-line-length
	let newString = str.toLowerCase();

	const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç'.split('');
	const to   = 'aaaaaeeeeeiiiiooooouuuunc'.split('');

	from.forEach((char, index) => {
		newString = newString.replace(new RegExp(char, 'g'), to[index]);
	});

	const tr = 'A B V G D E ZH Z I Y K L M N O P R S T U F H C CH SH SCH  Y  E YU YA a b v g d e zh z i y k l m n o p r s t u f h c ch sh sch  y  e yu ya e e'.split(' ');
	let result = '';
	for (let i = 0; i < newString.length; ++i) {
		const cc = newString.charCodeAt(i);
		result += (cc === 1025 ? 'E' : (cc >= 1040 ? tr[cc - 1040] : newString[i]));
	}
	return result;
}

export const stringToUrl: (str: string) => string = (str) => {
	return transliterate(str)
		.replace(/[^0-9A-Za-z\s-]+/g, '')
		.replace(/\s/g, '-')
		.replace('\'', '')
		.toLowerCase();
};

export function debounce<F extends Function>(func: F, wait: number):F {
	let timeoutID: number;
	let time = wait;

	Number.isFinite = Number.isFinite || function (value) {
		return typeof value === 'number' && isFinite(value);
	};

	Number.isInteger = Number.isInteger || function (value) {
		return typeof value === 'number'
			&& Number.isFinite(value)
			&& !(value % 1);
	};

	if (!Number.isInteger(time)) {
		console.log('Called debounce without a valid number');
		time = 300;
	}

	// conversion through any necessary as it wont satisfy criteria otherwise
	return <F><any>function (this:any, ...args: any[]) {
		clearTimeout(timeoutID);
		const self = this;

		timeoutID = window.setTimeout(() => {
			func.apply(self, args);
		}, time);
	};
}

export function equal(a, b) {
	return JSON.stringify(a) === JSON.stringify(b);
}

export const replaceExceptNumber = (str: string) => str.replace(/\D/g, '');