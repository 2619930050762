import * as React from 'react';
import {  RouteComponentProps, withRouter } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';

import '@app/scss/style.scss';

type PageProps = RouteComponentProps;

const LayoutShort: React.FC<PageProps> = (props) => {
	return(
		<div className="app-container">
			<Header/>
			<div className="app-container-content">
				{props.children}
			</div>
		</div>
	);
};

export default withRouter(LayoutShort) as any;