import * as React from 'react';

import '@app/scss/components/findVetBlock.scss';
import SearchField from '@app/components/Forms/SearchField/SearchField';

const FindVetBlock: React.FC = () => {
	return (
		<div className="find-vet">
			<div className="find-vet-left">
				<h3 className="find-vet-left__title">Find a veterinarian near you</h3>
				<SearchField placeholder="Enter your 5-digit ZIP code" iconLocation={true} widthBorder="0"
							 searchBody="/find-a-vet/search"/>
			</div>
			<div className="find-vet-right">
				<h4 className="find-vet-right__title">
					Have questions? Give us a call. One of our care coordinators would love to help.
				</h4>
				<div className="find-vet-right__availability">
					We are available 7am - 11pm Eastern time, including weekends and holidays.
				</div>
				<a className="find-vet-right__phone" href="tel:855-933-5683">
					<img className="find-vet-right__phone-icon"
						 src={require('@app/images/call_icon_white.svg')}
						 alt="Phone Icon"/>
					 <img
					 	className="find-vet-right__phone-icon find-vet-right__phone-icon_hovered"
						src ={require('@app/images/call_icon_white-filled.svg')}
						alt="Phone Icon"
					 />
					<span>(855) 933-5683</span>
				</a>
			</div>
		</div>
	);
};

export default FindVetBlock;
