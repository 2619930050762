import * as React from 'react';
import {  RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';
import FindVetBlock from '@app/components/UI/FindVetBlock/FindVetBlock';
import '@app/scss/style.scss';
import ScrollToTop from '@app/components/LocalCommon/ScrollToTop/ScrollToTop';

type PageProps = RouteComponentProps & {withScrollTop: boolean};

const LayoutFamilyResources: React.FC<PageProps> = ({children, withScrollTop  = true, ...rest}) => {
	const history = useHistory();
	return (
		<div className="app-container">
			<Header/>
			<div className="app-container-content">
				{children}
			</div>
			{
				<FindVetBlock/>
			}
			<Footer/>
			{withScrollTop && <ScrollToTop/>}
		</div>
	);
};

export default withRouter(LayoutFamilyResources) as any;