import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { phoneRegexp } from '@common/react/utils/validationHelpers';
import { List } from '@common/typescript/objects/List';

import { PetSpecies } from '@app/components/Pages/Area/AreaObjects';

import { rest } from '@app/components/Api';
import FormikField from '@app/components/Forms/CustomFormikField/CustomFormikField';
import { CustomPhoneControl } from '@app/components/Forms/CustomPhoneControl/CustomPhoneControl';
import CustomSelect from '@app/components/Forms/CustomSelect/CustomSelect';
import { numberBeautifyValidator, zipCodeValidator } from '@app/components/LocalCommon/Utils/Validators';
import CustomInput from '@app/components/UI/CustomInput/CustomInput';

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	zip: string;
	message: string;
	petName: string;
	petWeight: number | null;
	gender: number | null;
	petSpecies: number | null;
	captcha: string;
}

const beautifyValidator = (errorText: string, errorManual: string = '', maxLength: number = 255) => {
	return !errorManual ? Yup.string().required(errorText).max(maxLength) : Yup.string().email(errorManual).required(errorText);
};

const validationSchema = Yup.object({
	firstName: beautifyValidator('First Name is a required field'),
	lastName: beautifyValidator('Last Name is a required field'),
	email: beautifyValidator('Email is a required field', 'Email must be a valid Email'),
	phone: Yup.string().matches(phoneRegexp, 'Invalid phone number').required('Phone is a required field'),
	zip: zipCodeValidator(),
	petWeight: numberBeautifyValidator(
		"Pet's Weight must be a number",
		"Pet's Weight must be greater than zero",
		"Pet's Weight is a required field",
		"Pet's Weight must be a whole number (1lb or more, with no decimals)"
	),
	petSpecies: Yup.string().nullable().required("Pet's Species is a required field"),
	gender: Yup.string().nullable().required("Pet's Gender is a required field"),
	captcha: Yup.string().required('Please check the box to submit this form'),
});

const initialValues: FormValues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	zip: '',
	message: '',
	petName: '',
	petWeight: null,
	petSpecies: null,
	gender: null,
	captcha: '',
};

const GetInTouchForm: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
	const [sending, setSending] = React.useState(false);
	const [species, setSpecies] = React.useState<PetSpecies[]>([]);

	const [sent, setSent] = React.useState<boolean>(false);
	const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);

	const gender = [
		{ value: 1, label: 'Male' },
		{ value: 2, label: 'Female' },
	];

	const handleSubmit = (values: FormValues) => {
		if (isConfirmed) {
			setSending(true);

			rest.post('v1/getInTouch', values).then((response) => {
				onSubmit();
				setSent(true);
				setSending(false);
			});
		}
	};

	const requestSpecies = () => {
		rest.get<List<PetSpecies>>('v1/petspecies').then(res => setSpecies(res.list));
	};

	return !sent ? (
		<Formik
			onSubmit={handleSubmit}
			initialValues={initialValues}
			validationSchema={validationSchema}
			render={(formikBag: FormikProps<FormValues>) => (
				<Form className="get-in-touch__form" autoComplete="new-password">
					<div className="form-group">
						<CustomInput name="firstName" placeholder="First Name*" formikBag={formikBag} />
						<CustomInput name="lastName" placeholder="Last Name*" formikBag={formikBag} />
						<CustomInput name="petName" placeholder="Pet's Name*" formikBag={formikBag} />
						<CustomSelect
							name="petSpecies"
							placeholder="Pet's Species*"
							onSelect={(value, option) => {
								formikBag.setFieldValue('petSpecies', value);
							}}
							onChange={(value, option) => {
								formikBag.setFieldValue('petSpecies', value);
							}}
							onSearch={(value, option) => {
								formikBag.setFieldValue('petSpecies', value);
							}}
							options={species}
							showSearch={false}
							onFocus={requestSpecies}
							transform={true}
							formikBag={formikBag}
						/>
						<CustomSelect
							name="gender"
							placeholder="Gender*"
							onSelect={(value, option) => {
								formikBag.setFieldValue('gender', value);
							}}
							onChange={(value, option) => {
								formikBag.setFieldValue('gender', value);
							}}
							onSearch={(value, option) => {
								formikBag.setFieldValue('gender', value);
							}}
							options={gender}
							showSearch={false}
							onFocus={() => {}}
							formikBag={formikBag}
						/>
						<CustomInput type="number" name="petWeight" placeholder="Pet's Weight*" formikBag={formikBag} innerIcon="Lbs" />
						<CustomInput name="email" placeholder="Email Address*" formikBag={formikBag} removeSpace={true} />
						<div className="phone">
							<FormikField
								title="Phone Number"
								fieldName="phone"
								render={fieldProps => (
									<CustomPhoneControl
										autocomplete="phone-number-user-doesnt-want-to-autofill"
										placeholder="Phone Number*"
										fieldProps={fieldProps}
									/>
								)}
							/>
						</div>
						<CustomInput name="zip" placeholder="US Zip Code*" formikBag={formikBag} />
					</div>
					<CustomInput name="message" placeholder="Tell us what is going on with your pet" formikBag={formikBag} />
					<div className="text">
						By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message by Lap of Love.
						Message data rates may apply. Reply STOP to opt out of further messaging.
					</div>
					<FormikField
						containerClassName="captcha"
						fieldName="captcha"
						render={fieldProps => (
							<ReCAPTCHA
								sitekey="6LezTMIcAAAAACaXvLNse5bmTJpwqs0Ezm_bhTrU"
								onChange={(value) => {
									if (value) {
										formikBag.setFieldValue('captcha', value);
										setIsConfirmed(true);
									} else {
										formikBag.setFieldValue('captcha', '');
										setIsConfirmed(false);
										setSending(false);
									}
								}}
								onExpired={() => formikBag.setFieldValue('captcha', 'expired')}
								hl="en"
							/>
						)}
					/>
					<div className="text-center">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={sending}
						>
							Send
						</button>
					</div>
					{sending ? (
						<div className="lds-ring">
							<div />
							<div />
							<div />
							<div />
						</div>
					) : null}
				</Form>
			)}
		/>
	) : (
		<></>
	);
};

export default GetInTouchForm;
