import * as React from 'react';
import {NavLink} from 'react-router-dom';

import {footerMenu} from '@app/components/UI/Footer/FooterMenuLinks';
import {FooterMenuItem} from '@app/objects/CustomMenuItem';
import GetInTouchButton from '@app/components/Forms/GetInTouch/GetInTouchButton';

const carrotIcon = require('@app/images/carrot_white.svg');

interface FooterMenuProps {
	isMobile?: boolean;
}

const FooterMenuColumn: React.FC<{title: string}> = ({ title }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleOpen = () => setIsOpen(prevState => !prevState);

	return (
		<div className="site-footer-col">
			<label className="site-footer-col__title" onClick={toggleOpen}>
				{title}
				<img
					className={`site-footer-col__carrot site-footer-col__carrot_${isOpen ? 'up' : 'down'}`}
					width={24}
					height={24}
					alt="carrot"
					src={carrotIcon}
				/>
			</label>
			<div className={`site-footer-col__content ${isOpen ? 'site-footer-col__content_open' : ''}`}>
				{footerMenu[title].map((item: FooterMenuItem, index) => {
					if (item.name === 'Contact Us') {
						return <GetInTouchButton key={index} btnText={item.name}/>;
					}

					if (item.name === 'Media Kit' || item.name === 'Join Our Team' || item.newTab) {
						return (
							<a href={item.path} className="site-footer-col__link" target="_blank" key={index} rel="noopener noreferrer">
								{item.name}
							</a>
						);
					}

					return <NavLink to={item.path} className="site-footer-col__link" key={index}>{item.name}</NavLink>;
				})}
			</div>
		</div>
	);
};

const FooterMenu: React.FC<FooterMenuProps> = ({ isMobile = false }) => {
	return (
		<div className={`site-footer-menu ${isMobile ? 'site-footer-menu_mobile' : ''}`}>
			{Object.keys(footerMenu).map((title, index) => <FooterMenuColumn title={title} key={index} />)}
		</div>
	);
};

export default FooterMenu;
