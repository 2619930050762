import * as React from 'react';

import { Link } from 'react-router-dom';

import FooterMenu from '@app/components/UI/Footer/FooterMenu';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 600px)');

		const listenerFunc = () => {
			if (mql.matches) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	return <footer className="site-footer">
		<div className="site-footer-wrapper">
			<div className="site-footer-body">
				<img className="site-footer-body__logo" src={require('@app/images/logo_glyph.svg')} alt="Logo" />
				<FooterMenu />
				<div className="site-footer-contacts">
					<h4 className="site-footer-contacts__title">{isMobile ? <a className="site-footer-contacts__link" href="https://info.lapoflove.com/senior-pet-digest" target="_blank">Subscribe</a> : 'Subscribe'} to the Senior Pet Digest</h4>
					{!isMobile &&
						<p className="site-footer-contacts__text">
							<a className="site-footer-contacts__link" href="https://info.lapoflove.com/senior-pet-digest" target="_blank">Subscribe here</a>&nbsp;
							to view our monthly newsletter with helpful pet articles, insights, and resources.
						</p>
					}
					<FooterMenu isMobile />
					<div className="site-footer-contacts-connect">
						<img className="site-footer-contacts-connect__logo" src={require('@app/images/logo_glyph.svg')} alt="Logo" />
						<div className="social-block">
							<b>Connect with us:</b>
							<div className="social-block-list">
								<a href="https://www.facebook.com/lapoflove" target="_blank">
									<img className="social-link" src={require('@app/images/social/icon_facebook.svg')} alt="Facebook"/>
								</a>
								<a href="https://twitter.com/LapofLoveVets" target="_blank" className="twitter">
									<img className="social-link" src={require('@app/images/social/icon-twitter.svg')} alt="Twitter"/>
								</a>
								<a href="https://www.instagram.com/lapofloveveterinaryhospice/" target="_blank">
									<img className="social-link" src={require('@app/images/social/icon_instagram.svg')} alt="Instagram"/>
								</a>
								<a href="https://www.youtube.com/user/Lapoflove" target="_blank">
									<img className="social-link" src={require('@app/images/social/icon_youtube.svg')} alt="Youtube"/>
								</a>
								<a href="https://www.linkedin.com/company/lap-of-love-veterinary-hospice" target="_blank">
									<img className="social-link" src={require('@app/images/social/icon_linkedin.svg')} alt="Linkedin"/>
								</a>
								<a href="https://www.tiktok.com/@lapoflovevets" target="_blank">
									<div className="social-link tiktok">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
											<path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
										</svg>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="site-footer-bottom">
				<span className="site-footer-bottom__copyright">
					© 2010-{year} Lap of Love. All rights reserved.
				</span>
				<div className="site-footer-bottom__policies">
					<Link to="/policies/privacy-policy" >
						Privacy Policy
					</Link>
					<Link to="/policies/terms-and-conditions">
						Terms and Conditions
					</Link>
					<Link to="/policies/accessibility-policy">
						Accessibility Policy
					</Link>
				</div>
			</div>
		</div>
	</footer>;
};

export default Footer;