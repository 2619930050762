import * as React from 'react';

import {Field, FormikProps} from 'formik';

import {Select} from 'antd';

import {ListOption} from '@app/objects/SidebarProps';

interface CustomSelectProps {
	placeholder?: string;
	options:  any;
	onSelect: any;
	onChange: (value: string, option: any) => void;
	showSearch: boolean;
	name: string;
	onSearch: any;
	onFocus: () => void;
	transform?: boolean;
	formikBag:  FormikProps<any>;
	parentContainer?: string;
	disabled?: boolean;
	annotation?: string;
	value?:string;
	showArrow?: boolean;
	open?: any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	placeholder = 'Please make a selection',
	options,
	onSelect,
	onChange,
	showSearch,
	name,
	onSearch,
	onFocus,
	transform = false,
	formikBag,
	parentContainer,
	disabled = false,
	annotation,
	value,
	showArrow = true,
	open
	}) => {

	const listOptions: ListOption[] = [];
	const transformOptions = () => {
		transform && options.map((item) => {
			listOptions.push({value: item.id ? item.id : item.name, label: item.name ? item.name : item.label});
		});
	};
	transformOptions();

	const filterOption = (input: string, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const [isOpen, setIsOpen] = React.useState<boolean | undefined>(open !== undefined ? open : undefined);

	React.useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<div className={
			!annotation ? 'search-field search-field_auto custom-select' :
				'search-field search-field_auto custom-select with-annotation'
		}>
			<div className="search-field__annotation">{annotation}</div>
			<Field
				name={name}
				render={(field) => {

					return(
						<div className={field.form.errors[name] && field.form.touched[name] ? 'custom-field error' : 'custom-field'}>
							<Select
								showSearch={showSearch}
								placeholder={placeholder}
								options={listOptions.length ? listOptions : options}
								optionFilterProp="label"
								onSelect = {onSelect}
								onChange = {onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								onFocus={() => {
									showArrow ? setIsOpen(undefined) : setIsOpen(false);

									if (onFocus) {
										onFocus();
									}
								}}
								className={!showArrow ? 'noShowArrow' : ''}
								dropdownClassName="custom-field-dropdown"
								onBlur={() => field.form.setFieldTouched(name, true)}
								open={isOpen}
								// @ts-ignore
								getPopupContainer={parentContainer ? () => document.querySelector(parentContainer) : undefined}
								disabled={disabled}
								value={value}
								allowClear={!showArrow}
							/>
							{
								field.form.errors[name] && field.form.touched[name] ? <div className="custom-input__error"> {field.form.errors[name]}</div> : null
							}
						</div>
					);
				}}
			/>
		</div>
	);
};

export default CustomSelect;