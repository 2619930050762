import * as React from 'react';

import BannerGlobal from '@app/components/UI/BannerGlobal/BannerGlobal';
import {rest} from '@app/components/Api';
import {AttentionMessage} from '@app/objects/AttentionMessage';

const AttentionMessagesWrapper = () => {
	const key = 'attention-messages';

	const [attentionMessages, setAttentionMessages] = React.useState<AttentionMessage[]>();
	const [readMessages, setReadMessages] = React.useState<number[]>([]);

	const markMessageAsRead = (id: number): void => {
		setReadMessages([...readMessages, id]);
	};

	React.useEffect(() => {
		if (readMessages.length > 0) {
			localStorage.setItem(key, JSON.stringify(readMessages));
		}
	}, [readMessages]);

	React.useEffect(() => {
		setReadMessages(JSON.parse(localStorage.getItem(key) || '[]'));
	}, []);

	React.useEffect(() => {
		rest.get<AttentionMessage[]>('v1/attentionMessages')
			.then((res) => {
				setAttentionMessages(res);
			});
	}, []);

	return (
		<>
			{attentionMessages?.filter(message => readMessages.indexOf(message.id) === -1)?.map(message =>
				<BannerGlobal key={message.id} onBannerClose={() => markMessageAsRead(message.id)}>
					<>{message.description}</>
				</BannerGlobal>)}
		</>
	);
};

export default AttentionMessagesWrapper;