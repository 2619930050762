import * as React from 'react';

import {Field, FieldProps} from 'formik';

interface CustomInputProps {
	type?: string;
	name: string;
	placeholder: string;
	component?: any;
	options?:any;
	stateSelected?: string | number;
	setStateSelected?: any;
	optionName_1?: string;
	fieldValue?: string;
	setFieldValue?: any;
	optionName_2?: string;
	formikBag?: any;
	innerIcon?: string | JSX.Element;
	removeSpace?: boolean;
	handleOnChange?: (e) => void;
}

const defaultInput: React.FC<FieldProps<any>> = (props: FieldProps) => {
	console.log(props, 'FieldPROPS');
	return (
		<input value={props.field.value} onChange={props.field.onChange} onBlur={props.field.onBlur} />
	);
};

const CustomInput: React.FC<CustomInputProps> = (
	{type, 
	name, 
	placeholder,
	component,
	formikBag,
	innerIcon,
	removeSpace = false,
	handleOnChange = () => {}
	}) => {
	const [nameFocused, setNameFocused] = React.useState(false);

	return (
		<div 
			className={formikBag.errors[name] && formikBag.touched[name] ?
				(nameFocused ? 'custom-input error focused ' : 'custom-input error') :
				(nameFocused ? 'custom-input focused' : 'custom-input')}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
				if (removeSpace) {
					formikBag.setFieldValue(name, event.target.value.trim());
				}
				handleOnChange(event);
			}}
			// @ts-ignore
			onFocus={event => setNameFocused(true)}
			// @ts-ignore
			onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
				setNameFocused(false);
				if (removeSpace) {
					formikBag.setFieldValue(name, event.target.value.trim());
				}
			}}
		>
			
			<Field
				type={type}
				component={component}
				name={name}
				placeholder={placeholder}
				id={name}
				autoComplete={name}
				// onBlur={onBlur}
			/>
			<div className="custom-input__inner-icon">
				{innerIcon ?? innerIcon}
			</div>
			<div className="custom-input__error">{formikBag.errors[name] && formikBag.touched[name] ? formikBag.errors[name] : null}</div>
			<div className="custom-input__placeholder-top">{placeholder}</div>
		</div>
	);
};

export default CustomInput;
