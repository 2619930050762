import {ReducersMapObject} from 'redux';

import * as Items from '@common/react/store/ItemList';
import * as Item from '@common/react/store/Item';
import {BaseApplicationState, BaseAppThunkAction, baseReducers} from '@common/react/store';
import {PageItemState, reducer as PageStateReducer} from '@common/react/store/PageItem';

import {User} from '@app/objects/User';
import {Page} from '@app/objects/Page';
import {Inquiry} from '@app/objects/Inquiry';
import {HomePageContent} from '@app/objects/pageContent/HomePageContent';
import {BaseContentPage, ContentPage} from '@app/objects/ContentPage';
import {HomePage} from '@app/objects/pages/HomePage';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	inquiries: Items.ItemsState<Inquiry>;

	user: Item.ItemState<User>;
	users: Items.ItemsState<User>;
	page: Item.ItemState<Page>;
	pages: Items.ItemsState<Page>;

	homePage: PageItemState<HomePage>;
	veterinaryHospicePage: PageItemState<BaseContentPage>;
	euthanasiaPage: PageItemState<BaseContentPage>;
	aftercarePage: PageItemState<BaseContentPage>;
	teleadvicePage: PageItemState<BaseContentPage>;
	petLossSupportGroupPage: PageItemState<BaseContentPage>;
	howWillIKnowPage: PageItemState<BaseContentPage>;
	newsletterPage: PageItemState<BaseContentPage>;
	faqPage: PageItemState<BaseContentPage>;
	memorialsPage: PageItemState<BaseContentPage>;
	testimonialsPage: PageItemState<BaseContentPage>;
	angelFundPage: PageItemState<BaseContentPage>;
	petLossSupportPage: PageItemState<BaseContentPage>;
	whoWeArePage: PageItemState<BaseContentPage>;
	supportCenterPage: PageItemState<BaseContentPage>;
	studentExternshipPage: PageItemState<BaseContentPage>;
	veterinarianPage: PageItemState<BaseContentPage>;
	careCoordinatorPage: PageItemState<BaseContentPage>;
	findAVetPage: PageItemState<BaseContentPage>;
	burialPage: PageItemState<BaseContentPage>;
	aquamationPage: PageItemState<BaseContentPage>;
	covidPage: PageItemState<BaseContentPage>;
	beginningToHealPage: PageItemState<BaseContentPage>;
	childrenGriefPage: PageItemState<BaseContentPage>;
	resourceLinePage: PageItemState<BaseContentPage>;
	qualityOfLifePage: PageItemState<BaseContentPage>;
	loginPage: PageItemState<BaseContentPage>;
	privacyPolicyPage: PageItemState<BaseContentPage>;
	termsAndConditionsPage: PageItemState<BaseContentPage>;
	accessibilityPolicyPage: PageItemState<BaseContentPage>;
	qualityOfLiveAssessmentPage: PageItemState<BaseContentPage>;
	hospicePainManagementPage: PageItemState<BaseContentPage>;
	euthanasiaSedationProtocolsPage: PageItemState<BaseContentPage>;
	euthanasiaBestPracticesPage: PageItemState<BaseContentPage>;
	geriatricClinicTipsPage: PageItemState<BaseContentPage>;
	veterinaryFaqPage: PageItemState<BaseContentPage>;

	blogPage: PageItemState<BaseContentPage>;
	videosPage: PageItemState<BaseContentPage>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	inquiries: Items.getReducer<Inquiry>('inquiries'),
	user: Item.getReducer<User>('user'),
	users: Items.getReducer<User>('users'),
	page: Item.getReducer<Page>('page'),
	pages: Items.getReducer<Page>('pages'),

	homePage: PageStateReducer<ContentPage<HomePageContent>>('homePage'),
	veterinaryHospicePage: PageStateReducer<BaseContentPage>('veterinaryHospicePage'),
	euthanasiaPage: PageStateReducer<BaseContentPage>('euthanasiaPage'),
	aftercarePage: PageStateReducer<BaseContentPage>('aftercarePage'),
	teleadvicePage: PageStateReducer<BaseContentPage>('teleadvicePage'),
	petLossSupportGroupPage: PageStateReducer<BaseContentPage>('petLossSupportGroupPage'),
	howWillIKnowPage: PageStateReducer<BaseContentPage>('howWillIKnowPage'),
	newsletterPage: PageStateReducer<BaseContentPage>('newsletterPage'),
	faqPage: PageStateReducer<BaseContentPage>('faqPage'),
	memorialsPage: PageStateReducer<BaseContentPage>('memorialsPage'),
	testimonialsPage: PageStateReducer<BaseContentPage>('testimonialsPage'),
	angelFundPage: PageStateReducer<BaseContentPage>('angelFundPage'),
	petLossSupportPage: PageStateReducer<BaseContentPage>('petLossSupportPage'),
	whoWeArePage: PageStateReducer<BaseContentPage>('whoWeArePage'),
	supportCenterPage: PageStateReducer<BaseContentPage>('supportCenterPage'),
	studentExternshipPage: PageStateReducer<BaseContentPage>('studentExternshipPage'),
	veterinarianPage: PageStateReducer<BaseContentPage>('veterinarianPage'),
	careCoordinatorPage: PageStateReducer<BaseContentPage>('careCoordinatorPage'),
	findAVetPage: PageStateReducer<BaseContentPage>('findAVetPage'),
	burialPage: PageStateReducer<BaseContentPage>('burialPage'),
	covidPage: PageStateReducer<BaseContentPage>('covidPage'),
	beginningToHealPage: PageStateReducer<BaseContentPage>('beginningToHealPage'),
	childrenGriefPage: PageStateReducer<BaseContentPage>('childrenGriefPage'),
	resourceLinePage: PageStateReducer<BaseContentPage>('resourceLinePage'),
	qualityOfLifePage: PageStateReducer<BaseContentPage>('qualityOfLifePage'),
	loginPage: PageStateReducer<BaseContentPage>('loginPage'),
	aquamationPage: PageStateReducer<BaseContentPage>('aquamationPage'),
	privacyPolicyPage: PageStateReducer<BaseContentPage>('privacyPolicyPage'),
	termsAndConditionsPage: PageStateReducer<BaseContentPage>('termsAndConditionsPage'),
	accessibilityPolicyPage: PageStateReducer<BaseContentPage>('accessibilityPolicyPage'),
	qualityOfLiveAssessmentPage: PageStateReducer<BaseContentPage>('qualityOfLiveAssessmentPage'),
	hospicePainManagementPage: PageStateReducer<BaseContentPage>('hospicePainManagementPage'),
	euthanasiaSedationProtocolsPage: PageStateReducer<BaseContentPage>('euthanasiaSedationProtocolsPage'),
	euthanasiaBestPracticesPage: PageStateReducer<BaseContentPage>('euthanasiaBestPracticesPage'),
	geriatricClinicTipsPage: PageStateReducer<BaseContentPage>('geriatricClinicTipsPage'),
	veterinaryFaqPage: PageStateReducer<BaseContentPage>('veterinaryFaqPage'),
	blogPage: PageStateReducer<BaseContentPage>('blogPage'),
	videosPage: PageStateReducer<BaseContentPage>('videosPage')
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> extends BaseAppThunkAction<TAction, User, ApplicationState> {}
