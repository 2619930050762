import * as React from 'react';

import {FieldProps, ErrorMessage} from 'formik';

import {
	FormikInputProps as BaseFormikInputProps,
	FormikInputRenderFunc as BaseFormikInputRenderFunc
} from '@common/react/components/Forms/FormikInput/FormikInput';

export type FormikInputProps = BaseFormikInputProps;
export type FormikInputRenderFunc = BaseFormikInputRenderFunc;

const defaultRender = ({form, field}: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
	<input className="form-control" type="text" id={field.name} {...field} {...inputProps}/>;

const defaultErrorComponent: React.FC<{error: string | object}> = ({error}) =>
	<div className="validation-message">
		{typeof error === 'string' ? error : Object.keys(error)
			.filter(key => typeof error[key] === 'string')
			.map(key => error[key])
			.join(', ')}
	</div>;

export const FormikInput: React.FC<FormikInputProps> =
	({
		 fieldProps,
		 containerClassName = 'col-sm-6',
		 render = defaultRender,
		 title,
		 inputId,
		 // showValidateAfterSubmit = true,
		 inputProps,
		 ErrorComponent = defaultErrorComponent
	 }) => {
		const {form, field} = fieldProps;
		return <div className={(form.errors[field.name] && form.touched[field.name]) ? `input-field ${containerClassName} error` : `input-field ${containerClassName}`}>
			{/*{(showValidateAfterSubmit ? form.submitCount > 0 : true) && <ErrorMessage name={field.name} render={msg => <ErrorComponent error={msg}/>} />}*/}
			{(form.errors[field.name] && form.touched[field.name]) 
			? <ErrorMessage name={field.name} render={msg => <ErrorComponent error={msg}/>} /> : null }
			{render(fieldProps, inputProps)}
			{title && <label className="input-field-label" htmlFor={inputId || field.name}>{title}</label>}
		</div>;
	};